import dynamic from 'next/dynamic';

import type { FunctionComponent } from 'react';
import type { TypeFlexibleContentProps } from './types';

const Benefits = dynamic(() => import('@/organisms/benefits'));
const BuildingCarrousel = dynamic(
  () => import('@/templates/building-carrousel')
);
const Faqs = dynamic(() => import('@/organisms/faqs'));
const PopUpOpenPage = dynamic(() => import('@/organisms/pop-up-open-page'));
const Hero = dynamic(() => import('@/organisms/hero'));
const GeneralCards = dynamic(() => import('@/templates/general-cards'));
const LaVidaEnBeCasa = dynamic(() => import('@/organisms/la-vida-en-be-casa'));
const AboutUs = dynamic(() => import('@/templates/aboutUs'));
const AnchorMenu = dynamic(() => import('@/templates/anchor-menu'));
const Apartments = dynamic(() => import('@/templates/apartments'));
const Blog = dynamic(() => import('@/templates/blog'));
const CardsBlog = dynamic(() => import('@/templates/cards-blog-template'));
const ContactForm = dynamic(() => import('@/templates/contact-form'));
const CrossTemplate = dynamic(() => import('@/templates/cross-template'));
const ExploreZone = dynamic(() => import('@/templates/explore-zone'));
const Facilities = dynamic(() => import('@/templates/facilities'));
const FlexibleLegal = dynamic(() => import('@/templates/flexible-legal'));
const FlexibleText = dynamic(() => import('@/templates/flexible-text'));
const Gallery = dynamic(() => import('@/templates/gallery-template'));
const GenericActivities = dynamic(
  () => import('@/templates/generic-activities')
);
const Histories = dynamic(() => import('@/templates/histories'));
const Housing = dynamic(() => import('@/templates/housing'));
const MapTemplate = dynamic(() => import('@/templates/map-template'));
const NewOpening = dynamic(() => import('@/templates/new-opening'));
const Newsletter = dynamic(() => import('@/templates/newsletter'));
const Press = dynamic(() => import('@/templates/press-template'));
const PriceDetail = dynamic(() => import('@/templates/price-detail'));
const PriceSave = dynamic(() => import('@/templates/price-save'));
const Recomended = dynamic(() => import('@/templates/recomended'));
const SharedSpaces = dynamic(() => import('@/templates/shared-spaces'));
const SpecficActivities = dynamic(
  () => import('@/templates/specific-activities')
);
const Sustainability = dynamic(() => import('@/templates/sustainability'));
const WhatToDo = dynamic(() => import('@/templates/what-to-do-template'));
const WhatsIncluded = dynamic(
  () => import('@/templates/whats-included-template')
);
const WhereWeAre = dynamic(() => import('@/templates/where-we-are'));

const FLEXIBLE_CONTENT_MAPS = {
  Contenidoflexible_Contenido_Hero: Hero,
  Contenidoflexible_Contenido_NoTePierdasNada: Newsletter,
  Contenidoflexible_Contenido_Faqs: Faqs,
  Contenidoflexible_Contenido_LaVidaEnBeCasa: LaVidaEnBeCasa,
  Contenidoflexible_Contenido_Mapa: MapTemplate,
  Contenidoflexible_Contenido_ModuloTarjetas_beneficio: Benefits,
  Contenidoflexible_Contenido_ModuloTarjetas_blog: CardsBlog,
  Contenidoflexible_Contenido_ModuloTarjetas_historia: Histories,
  Contenidoflexible_Contenido_ModuloTarjetas_otros: GeneralCards,
  Contenidoflexible_Contenido_ModuloTarjetas_recomendaciones: Recomended,
  Contenidoflexible_Contenido_ModuloTarjetas_sobreNosotros: AboutUs,
  Contenidoflexible_Contenido_ModuloTarjetas_actividadesGenericas:
    GenericActivities,
  Contenidoflexible_Contenido_ModuloTarjetas_actividadesEspecificas:
    SpecficActivities,
  Contenidoflexible_Contenido_ModuloTarjetas_instalaciones: Facilities,
  Contenidoflexible_Contenido_Apartamentos: Apartments,
  Contenidoflexible_Contenido_Alojamientos: Housing,
  Contenidoflexible_Contenido_Cross: CrossTemplate,
  Contenidoflexible_Contenido_QueIncluye: WhatsIncluded,
  Contenidoflexible_Contenido_QueHacer: WhatToDo,
  Contenidoflexible_Contenido_ExploreZone: ExploreZone,
  Contenidoflexible_Contenido_Press: Press,
  Contenidoflexible_Contenido_NewOpening: NewOpening,
  Contenidoflexible_Contenido_Gallery: Gallery,
  Contenidoflexible_Contenido_Sustainability: Sustainability,
  Contenidoflexible_Contenido_EstamosAqui: WhereWeAre,
  Contenidoflexible_Contenido_MasInformacion: ContactForm,
  Contenidoflexible_Contenido_FiltroPosts: Blog,
  Contenidoflexible_Contenido_LegalFlexible: FlexibleLegal,
  Contenidoflexible_Contenido_TextoFlexible: FlexibleText,
  Contenidoflexible_Contenido_Precios_Detail: PriceDetail,
  Contenidoflexible_Contenido_Precios_Save: PriceSave,
  Contenidoflexible_Contenido_AnchorMenu: AnchorMenu,
  Contenidoflexible_Contenido_EspaciosComunes: SharedSpaces,
  Contenidoflexible_Contenido_BuildingCarrousel: BuildingCarrousel,
  Contenidoflexible_Contenido_Popupopenpage: PopUpOpenPage,
};

/**
 * FlexibleContent
 */
// TODO: Fix types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const FlexibleContent: FunctionComponent<TypeFlexibleContentProps> = ({
  flexibleContent,
}: TypeFlexibleContentProps) =>
  flexibleContent.map((flexible, index: number) => {
    if (FLEXIBLE_CONTENT_MAPS.hasOwnProperty(flexible?.__typename)) {
      const Component = FLEXIBLE_CONTENT_MAPS[flexible.__typename];

      // TODO: Fix types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <Component {...flexible} index={index} key={index} />;
    }

    // TODO: Remove this when all sections are included
    return (
      <div key={index}>
        {`Section with name "${flexible?.__typename}" not included in [...uri.js]`}
      </div>
    );
  });

FlexibleContent.displayName = 'FlexibleContent';
